import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

//Environment
import {environment} from '../../../environments/environment';

//Services

//Models



const httpOptions = { headers: new HttpHeaders(environment.defaultHeaders) };

@Injectable()

export class Req {

  constructor(
    private http: HttpClient,
   ){}

  get(path: string, headers: {} ) {
    return this.http.get<any>(environment.apiUrl + path, {headers: new HttpHeaders(headers)});
  }

  post(path: string, data: object, headers: {}) {
    return this.http.post<any>(environment.apiUrl + path, data, {headers: new HttpHeaders(headers)});
  }

  put(path: string, data: object, headers: {}) {
    return this.http.put<any>(environment.apiUrl + path, data, {headers: new HttpHeaders(headers)});
  }

  delete(path: string, headers: {}) {
    return this.http.delete<any>(environment.apiUrl + path, {headers: new HttpHeaders(headers)});
  }

}
