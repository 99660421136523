import {EventModel} from './event';
import {GameTypeModel} from './game-type';

interface ILeaguestatGameModel {
  id: number;
  clientCode: string;
  gameId: number;
  seasonId: number;
  event: EventModel;
  homeTeamId: number;
  visitorTeamId: number;
  venueId: number;
  gameDate: string;
  gameTime: string;
  timeZone: string;
  gameType: GameTypeModel;
}

export class LeaguestatGameModel {
  public id: number;
  public clientCode: string;
  public gameId: number;
  public seasonId: number;
  public event: EventModel;
  public homeTeamId: number;
  public visitorTeamId: number;
  public venueId: number;
  public gameDate: string;
  public gameTime: string;
  public timeZone: string;
  public gameType: GameTypeModel;

  constructor();
  constructor(obj: ILeaguestatGameModel);
  constructor(obj?: any){
    this.id = obj && obj.id || 0;
    this.clientCode = obj && obj.clientCode || "";
    this.gameId = obj && obj.gameId || 0;
    this.seasonId = obj && obj.seasonId || 0;
    this.event = obj && obj.event || undefined;
    this.homeTeamId = obj && obj.homeTeamId || 0;
    this.visitorTeamId = obj && obj.visitorTeamId || 0;
    this.venueId = obj && obj.venueId || 0;
    this.gameDate = obj && obj.gameDate || "";
    this.gameTime = obj && obj.gameTime || "";
    this.timeZone = obj && obj.timeZone || "";
    this.gameType = obj && obj.gameType || undefined;
  }
}
