import { Component, OnInit } from '@angular/core';

//Services
import {GameGenerationService} from '../../services/game-generation/game-generation.service';

//Models
import {GameModel} from '../../models/game';

class GameCreationModel {
  constructor(
    public data: GameModel,
    public errors: string[],
  ){};
}

@Component({
  selector: 'app-generating-games',
  templateUrl: './generating-games.component.html',
  styleUrls: ['./generating-games.component.scss']
})
export class GeneratingGamesComponent implements OnInit {

  loaded: boolean = false;
  creatingGame: boolean = false;
  games: GameCreationModel[] = [];

  constructor(
    private gameGenerationService: GameGenerationService,
  ){}

  ngOnInit() {
    this.loaded = false;
    this.createGame();
  }

  createGame() {
    var game = this.gameGenerationService.popGame();
    if (game == undefined) {
      this.loaded = true;
      return;
    }
    this.gameGenerationService.createLeaguestatGame(game).subscribe(
      data => {
        this.games.push(new GameCreationModel(data, []));
        this.createGame();
      },
      error => {
        if (error.error.errors) {
          this.games.push(new GameCreationModel(game, error.error.errors));
        } else {
          this.games.push(new GameCreationModel(game, ["Something went terribly wrong."]))
        }
        this.createGame();
      },
    );
  }

  reCreateGame(index: number) {
    this.gameGenerationService.createLeaguestatGame(this.games[index].data).subscribe(
      data => {
        this.games[index].data = data;
      },
      error => {
        if (error.error.errors) {
          this.games[index].errors = error.error.errors;
        } else {
          this.games[index].errors = ["Something went terribly wrong."]
        }
      },
    );
  }

}
