import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication/authentication.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  title = 'app';

  constructor(
  	private router: Router,
  	private authenticationService: AuthenticationService
  ){}

  ngOnInit() {
  }

  logout() {
  	this.authenticationService.logout();
  	this.router.navigate(['/']);
  }

}
