import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

//Services
import {Req} from '../request/request.service';

//Models
import {VenueModel} from '../../models/venue';
import {FacilityModel} from '../../models/facility';
import {EncoderModel} from '../../models/encoder';
import {PixellotModel} from '../../models/pixellot';
import {ClientVenueFacilityLinkModel} from '../../models/client-venue-facility-link';


@Injectable({
  providedIn: 'root'
})

export class FacilityService {

  mappedVenues: {[clientCode: string]: {[id: number]: VenueModel}} = {};
  mappedFacilities: {[id: number]: FacilityModel} = undefined;
  mappedEncoders: {[id: number]: EncoderModel} = undefined;
  mappedPixellotUnits: {[id: number]: PixellotModel} = undefined;
  mappedLinkedVenues: {[clientcode: string]: ClientVenueFacilityLinkModel[]} = {};

  constructor(
    private req: Req,
   ){}

  private createFacilitiesArray(mappedFacilities: {[id: number]: FacilityModel}): FacilityModel[] {
    let facilities: FacilityModel[] = [];
    for (let key in mappedFacilities) {
      facilities.push(mappedFacilities[key]);
    }
    return facilities;
  }

  private createVenuesArray(mappedVenues: {[id: number]: VenueModel}): VenueModel[] {
    let venues: VenueModel[] = [];
    for (let key in mappedVenues) {
      venues.push(mappedVenues[key]);
    }
    return venues;
  }

  private createEncodersArray(mappedEncoders: {[id: number]: EncoderModel}): EncoderModel[] {
    let encoders: EncoderModel[] = [];
    for (let key in mappedEncoders) {
      encoders.push(mappedEncoders[key]);
    }
    return encoders;
  }

  private createPixellotUnitsArray(mappedPixellotUnits: {[id: number]: PixellotModel}): PixellotModel[] {
    let pixellotUnits: PixellotModel[] = [];
    for (let key in mappedPixellotUnits) {
      pixellotUnits.push(mappedPixellotUnits[key]);
    }
    return pixellotUnits;
  }

  public getVenues(forceUpdate: boolean, clientCode: string) {
    if (this.mappedVenues[clientCode] != undefined && forceUpdate == false) {
      return Observable.create((observer) => {observer.next();}).pipe(map((response: any) => {return this.createVenuesArray(this.mappedVenues[clientCode]);}));
    } else {
      return this.req.get("/leaguestat/" + clientCode + "/venues", {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')}).pipe(map((response: any) => {
        this.mappedVenues[clientCode] = {};
        if (response && response.data) {
          for (var i in response.data) {
            var venue = new VenueModel();
            venue.id = response.data[i].Id
            venue.name = response.data[i].Name
            venue.location = response.data[i].Location

            this.mappedVenues[clientCode][venue.id] = venue
          }
        }
        return this.createVenuesArray(this.mappedVenues[clientCode]);
      }));
    }
  }

  public getFacilities(forceUpdate: boolean) {
    if (this.mappedFacilities != undefined && forceUpdate == false) {
      return Observable.create((observer) => {observer.next();}).pipe(map((response: any) => {return this.createFacilitiesArray(this.mappedFacilities);}));
    } else {
      return this.req.get("/htv/facilities", {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')}).pipe(map((response: any) => {
        this.mappedFacilities = {};
        if (response && response.data) {
          for (var i in response.data) {

            var facility = new FacilityModel();
            facility.id = response.data[i].facilityId;
            facility.name = response.data[i].name;
            facility.country = response.data[i].countryName;
            facility.state = response.data[i].state;
            facility.city = response.data[i].city;
            facility.address = response.data[i].address;
            facility.zip = response.data[i].zip;
            facility.streamingAllowed = response.data[i].streamingAllowed;

            if (response.data[i].encoder.id != null) {
              var encoder = new EncoderModel();
              encoder.id = response.data[i].encoder.id;
              encoder.name = response.data[i].encoder.name;
              facility.encoder = encoder;
            }
            if (response.data[i].pixellotUnit.id != null) {
              var pixellotUnit = new PixellotModel();
              pixellotUnit.id = response.data[i].pixellotUnit.id;
              pixellotUnit.name = response.data[i].pixellotUnit.name;
              pixellotUnit.venue = response.data[i].pixellotUnit.venueName;
              pixellotUnit.clubId = response.data[i].pixellotUnit.club.id;
              pixellotUnit.clubName = response.data[i].pixellotUnit.club.name;
              facility.pixellotUnit = pixellotUnit;
            }

            this.mappedFacilities[facility.id] = facility;
          }
        }
        return this.createFacilitiesArray(this.mappedFacilities);
      }));
    }
  }

  public getEncoders(forceUpdate: boolean) {
    if (this.mappedEncoders != undefined && forceUpdate == false) {
      return Observable.create((observer) => {observer.next();}).pipe(map((response: any) => {return this.createEncodersArray(this.mappedEncoders);}));
    } else {
      return this.req.get("/htv/encoders", {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')}).pipe(map((response: any) => {
        this.mappedEncoders = {};
        if (response && response.data) {
          for (var i in response.data) {
            var encoder = new EncoderModel();
            encoder.id = response.data[i].id
            encoder.name = response.data[i].name

            this.mappedEncoders[encoder.id] = encoder;
          }
        }
        return this.createEncodersArray(this.mappedEncoders);
      }));
    }
  }

  public getPixellotUnits(forceUpdate: boolean) {
    if (this.mappedPixellotUnits != undefined && forceUpdate == false) {
      return Observable.create((observer) => {observer.next();}).pipe(map((response: any) => {return this.createPixellotUnitsArray(this.mappedPixellotUnits);}));
    } else {
      return this.req.get("/htv/pixellotUnits", {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')}).pipe(map((response: any) => {
        this.mappedPixellotUnits = {};
        if (response && response.data) {
          for (var i in response.data) {
            var pixellotUnit = new PixellotModel();
            pixellotUnit.id = response.data[i].id;
            pixellotUnit.name = response.data[i].name;
            pixellotUnit.venue = response.data[i].venueName;
            pixellotUnit.clubId = response.data[i].club.id;
            pixellotUnit.clubName = response.data[i].club.name;

            this.mappedPixellotUnits[pixellotUnit.id] = pixellotUnit;
          }
        }
        return this.createPixellotUnitsArray(this.mappedPixellotUnits);
      }));
    }
  }

  public getLinkedFacilities(forceUpdate: boolean, clientCode: string) {
    if (this.mappedLinkedVenues[clientCode] !=  undefined && forceUpdate == false) {
      return Observable.create((observer) => {observer.next();}).pipe(map((response: any) => {return this.mappedLinkedVenues[clientCode];}));
    } else {
      return this.req.get("/htv/linked/facilities/" + clientCode, {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')}).pipe(map((response: any) => {
        this.mappedLinkedVenues[clientCode] = [];
        if (response && response.data) {
          for (var i in response.data) {
            var linkedVenue = new ClientVenueFacilityLinkModel();
            linkedVenue.clientCode = response.data[i].clientCode;
            linkedVenue.venueId = response.data[i].leaguestatVenueId;
            linkedVenue.facilityId = response.data[i].htvFacilityId;

            this.mappedLinkedVenues[clientCode].push(linkedVenue);
          }
        }
        return this.mappedLinkedVenues[clientCode];
      }));
    }
  }

  public createFacility(facility: FacilityModel) {
    var data = {
      "name": facility.name,
      "country": facility.country,
      "state": facility.state,
      "city": facility.city,
      "address": facility.address,
      "zip": facility.zip,
      "phone": facility.phone,
      "streamingAllowed": facility.streamingAllowed,
    }
    if (facility.encoder != undefined) {
      data["encoderId"] = facility.encoder.id;
    }
    if (facility.pixellotUnit != undefined) {
      data["pixellotUnitId"] = facility.pixellotUnit.id;
    }
    return this.req.post("/htv/facilities", facility, {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')}).pipe(map((response: any) => {
      if (response && response.data) {
        facility.id = response.data.facilityId;
        this.updateLocalFacility(facility);
      }
    }));;
  }

  public updateVenueLink(clientCode: string, venueId: number, facilityId: number) {
    return this.req.post("/leaguestat/"+clientCode+"/link/facility",{clientCode:clientCode, leaguestatVenueId: venueId, htvFacilityId: facilityId}, {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')});
  }

  public updateFacility(facility: FacilityModel) {
    var data = {
      "name": facility.name,
      "country": facility.country,
      "state": facility.state,
      "city": facility.city,
      "address": facility.address,
      "zip": facility.zip,
      "phone": facility.phone,
      "streamingAllowed": facility.streamingAllowed,
    }
    if (facility.encoder != undefined) {
      data["encoderId"] = facility.encoder.id;
    } else {
      data["encoderId"] = 0;
    }
    if (facility.pixellotUnit != undefined) {
      data["pixellotUnitId"] = facility.pixellotUnit.id;
    } else {
      data["pixellotUnitId"] = 0;
    }
    return this.req.put("/htv/facilities/"+facility.id ,data, {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')}).pipe(map((response: any) => {
        this.updateLocalFacility(facility);
      }));
  }

  public removeVenueLink(clientCode: string, venueId: number) {
    return this.req.delete("/leaguestat/"+clientCode+"/link/facility/"+venueId, {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')});
  }

  public getMappedFacility(clientCode: string, venueId: number) {
    for (var i in this.mappedLinkedVenues[clientCode]) {
      if (this.mappedLinkedVenues[clientCode][i].venueId == venueId) {
        return this.getFacility(this.mappedLinkedVenues[clientCode][i].facilityId);
      }
    }
    return undefined;
  }

  public getVenue(clientCode: string, venueId: number): VenueModel {
    return this.mappedVenues[clientCode][venueId];
  }

  public getFacility(facilityId: number): FacilityModel {
    return this.mappedFacilities[facilityId];
  }

  public getEncoder(encoderId: number): EncoderModel {
    return this.mappedEncoders[encoderId];
  }

  public getPixellotUnit(pixellotUnitId: number): PixellotModel {
    return this.mappedPixellotUnits[pixellotUnitId];
  }

  private updateLocalFacility(facility: FacilityModel) {
    this.mappedFacilities[facility.id] = facility;
  }
}
