import {FacilityModel} from './facility';
import {EventModel} from './event';
import {TeamModel} from './team';
import {GameTypeModel} from './game-type'
import {EncoderModel} from './encoder';
import {PixellotModel} from './pixellot';

interface IGameModel {
  id: number;
  leaguestatGameId: number;
  leagueId: number;
  seasonId: number;
  event: EventModel;
  homeTeam: TeamModel;
  visitorTeam: TeamModel;
  facility: FacilityModel;
  encoder: EncoderModel;
  pixellotUnit: PixellotModel;
  gameDate: string;
  gameTime: string;
  timeZone: string;
  gameType: GameTypeModel;
}

export class GameModel {
  public id: number;
  public leaguestatGameId: number;
  public leagueId: number;
  public seasonId: number;
  public event: EventModel;
  public homeTeam: TeamModel;
  public visitorTeam: TeamModel;
  public facility: FacilityModel;
  public encoder: EncoderModel;
  public pixellotUnit: PixellotModel;
  public gameDate: string;
  public gameTime: string;
  public timeZone: string;
  public gameType: GameTypeModel;

  constructor();
  constructor(obj: IGameModel);
  constructor(obj?: any){
    this.id = obj && obj.id || 0;
    this.leaguestatGameId = obj && obj.leaguestatGameId || 0;
    this.leagueId = obj && obj.leagueId || 0;
    this.seasonId = obj && obj.seasonId || 0;
    this.event = obj && obj.event || undefined;
    this.homeTeam = obj && obj.homeTeam || undefined;
    this.visitorTeam = obj && obj.visitorTeam || undefined;
    this.facility = obj && obj.facility || undefined;
    this.encoder = obj && obj.encoder || undefined;
    this.pixellotUnit = obj && obj.pixellotUnit || undefined;
    this.gameDate = obj && obj.gameDate || "";
    this.gameTime = obj && obj.gameTime || "";
    this.timeZone = obj && obj.timeZone || "";
    this.gameType = obj && obj.gameType || undefined;
  }
}
