import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';

//Services
import {FacilityService} from '../../services/facility/facility.service';
import {LeaguestatService} from '../../services/leaguestat/leaguestat.service'

//Models
import {VenueModel} from '../../models/venue';
import {FacilityModel} from '../../models/facility';
import {EncoderModel} from '../../models/encoder';
import {PixellotModel} from '../../models/pixellot';
import {ClientVenueFacilityLinkModel} from '../../models/client-venue-facility-link';
import {LinkedFacilityModel} from '../../models/linked-facility';


@Component({
  selector: 'app-venuesManagement',
  templateUrl: './ls-venues-management.component.html',
  styleUrls: ['./ls-venues-management.component.scss']
})
export class LSVenuesManagementComponent implements OnInit {

  loaded: boolean
  currentClient : string
  lsClients: string[] = [];

  venues: VenueModel[] = [];
  facilities: FacilityModel[] = [];
  linkedVenues: ClientVenueFacilityLinkModel[] = []
  mappedVenues: LinkedFacilityModel[] = [];

  filteredVenuesSearchText: string = "";
  filteredVenues: LinkedFacilityModel[] = [];

  editingVenues: {[id: string]:{[venueId:number]:boolean}} = {};

  constructor(
    private facilityService: FacilityService,
    private leaguestatService: LeaguestatService,
  ){}

  ngOnInit() {
    this.loaded = false;
    this.leaguestatService.getLSHTVClients(false).subscribe(
      data => {
        this.lsClients = data;
        this.currentClient = this.lsClients[0];
        this.getFacilities();
      },
      error => {
        alert("Failed to get HTV Leageuestat Clients");
      }
    );
  }

  updateClientCode(clientCode: string) {
    //Update client and then clear out old data before getting new data.
    this.loaded = false;
    this.currentClient = clientCode;
    this.filteredVenuesSearchText = "";
    this.getVenues();
  }

  removeFacilityMapping(venueId: number) {
   this.facilityService.removeVenueLink(this.currentClient, venueId).subscribe(
      data => {},
      error => { alert("Something went wrong when attempting to remove link to venue"); },
    );
  }

  updateFacilityMapping(venueId: number, facility: FacilityModel) {
    if (facility == undefined){
      this.facilityService.removeVenueLink(this.currentClient, venueId).subscribe(
        data => {
          this.getLinkedFacilities();
        },
        error => { alert("Something went wrong when attempting to remove link to venue"); },
      );
    } else {
      this.facilityService.updateVenueLink(this.currentClient, venueId, facility.id).subscribe(
        data => {
          this.getLinkedFacilities();
        },
        error => { alert("Something went wrong when updating link to facility. Make sure this facility is not mapped anywhere else."); },
      );
    }
  }

  resetEditingBoxes() {
    this.editingVenues[this.currentClient] = {};
    for (let i=0; i < this.mappedVenues.length; i++) {
      this.editingVenues[this.currentClient][this.mappedVenues[i].venue.id] = false;
    }
  }

  editFacility(venueId: number) {
    this.resetEditingBoxes();
    this.editingVenues[this.currentClient][venueId] = true;
  }

  saveFacility(venueId: number): void {
    this.editingVenues[this.currentClient][venueId] = false;
    this.getLinkedFacilities();
  }

  linkedVenuesFilterSearch(search: string, venues: LinkedFacilityModel[]): LinkedFacilityModel[] {
    this.filteredVenuesSearchText = search;
    search = search.replace(/^[ ]+|[ ]+$/g,'');
    var filteredVenues = venues;
    if (search.length > 0) {
      filteredVenues = [];
      for (var i=0; i < venues.length; i++) {
        let venueValue = (venues[i].venue.id+" "+venues[i].venue.name).toLowerCase();
        if(venueValue.includes(search.toLowerCase())) {
          filteredVenues.push(venues[i]);
        }
      }
    }
    return filteredVenues;
  }

  getFacilities() {
    // Get the HTV arenas
    this.facilityService.getFacilities(false).subscribe(
      data => {
        this.facilities = data;
        this.getVenues();
      },
      error => { alert("Failed to get facilities"); }
    );
  }

  getVenues() {
    // Get the LS venues for the currently selected client
    this.facilityService.getVenues(false, this.currentClient).subscribe(
      data => {
        this.venues = data;
        this.resetEditingBoxes()
        this.getLinkedFacilities();
      },
      error => { alert("Failed to get venues's"); console.log("ERROR: "+error) }
    );
  }

  getLinkedFacilities() {
    // Get the LS HTV linked arenas
    this.facilityService.getLinkedFacilities(true, this.currentClient).subscribe(
      data => {
        this.linkedVenues = data;
        this.mapLinkedFacilities();
        this.loaded = true;
      },
      error => { alert("Failed to get linked facilities list"); }
    );
  }

  mapLinkedFacilities() {
    let facility: FacilityModel;
    this.mappedVenues = [];

    for (let j=0; j < this.venues.length; j++) {
      let found = false;
      let venue = this.venues[j];
      for (let i=0; i < this.linkedVenues.length; i++) {
        let linkedVenue = this.linkedVenues[i];
        if (linkedVenue.venueId == venue.id) {
          facility = this.facilityService.getFacility(linkedVenue.facilityId);
          if (facility != undefined) {
            found = true;
          }
        }
      }
      if (found) {
        this.mappedVenues.push(new LinkedFacilityModel({venue: venue, facility: facility}));
      } else {
        this.mappedVenues.push(new LinkedFacilityModel({venue: venue, facility: undefined}));
      }
    }
    this.filteredVenues = this.linkedVenuesFilterSearch(this.filteredVenuesSearchText, this.mappedVenues);
  }

}
