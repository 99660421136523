import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';

//Environment
import {environment} from '../../../environments/environment';

//Services
import {Req} from '../request/request.service';

//Models



@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private http: HttpClient,
    private req: Req,
  ) {}

  login(email: string, password: string, appId: string, appKey: string) {
    let loginInfo = {
      "email": email,
      "password": password
    };
    return this.http.post<any>(environment.htvApiUrl + "/sessions/api_key", loginInfo, { headers: new HttpHeaders({APP_ID: appId, APP_KEY: appKey}) }).pipe(map((response: any) => {
      if (response && response.api_key) {
        localStorage.setItem('currentUser', response.api_key);
        localStorage.setItem('currentAppId', appId);
        localStorage.setItem('currentAppKey', appKey);
      }
      return response;
    }));
  }

  checkAdmin() {
    return this.req.get("/checkAdmin", {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')})
  }

  // noinspection JSMethodCanBeStatic
  logout() {
    localStorage.removeItem('currentUser');
  }
}
