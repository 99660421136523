import {Router, ActivatedRoute} from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { switchMap } from 'rxjs/operators';

//Services
import {FacilityService} from '../../../services/facility/facility.service';
import {HockeytvConstantsService} from '../../../services/hockeytv-constants/hockeytv-constants.service'

//Models
import {CountryModel} from '../../../models/country';
import {FacilityModel} from '../../../models/facility';
import {EncoderModel} from '../../../models/encoder';
import {PixellotModel} from '../../../models/pixellot';


@Component({
  selector: 'app-facility-view',
  templateUrl: './facility-view.component.html',
  styleUrls: ['./facility-view.component.scss']
})
export class FacilityViewComponent implements OnInit {

  loaded: boolean = false;
  errors: string[] = [];

  facility: FacilityModel;
  countries: CountryModel[] = [];
  states: string[] = [];
  cities: string[] = [];
  encoders: EncoderModel[] = [];
  pixellotUnits: PixellotModel[] = [];

  constructor(
  	private facilityService: FacilityService,
  	private hockeytvConstantsService: HockeytvConstantsService,
    private router: Router,
    private route: ActivatedRoute,
  ){}

  ngOnInit() {
    this.loaded = false;
    this.facility = new FacilityModel();
    this.facility.id = undefined;

    this.facilityService.getFacilities(true).subscribe(
      data => {
        let facilityId = undefined;
        this.route.params.subscribe(params => facilityId = params.facility_id);
        if (facilityId != undefined) {
          this.facility = this.facilityService.getFacility(facilityId);
        }
        if (this.facility == undefined) {
          this.errors.push("Facility "+facilityId+" was not found.");
        } else {
          this.loaded = true;
        }

        this.getCountries();
        this.getStates();
        this.getCities();
        this.getEncoders();
        this.getPixellotUnits();
      }
    )
  }

  submit(type: string) {
    this.errors = []
  	//Validate the facility before submition.
    if(this.facility.name.length <= 0) {
      this.errors.push("Must have a Facility name");
    }
    if (this.facility.country.length <= 0) {
      this.errors.push("Must select a country");
    }
    if(this.facility.state.length <= 0) {
      this.errors.push("Must have a state");
    }
    if(this.facility.city.length <= 0) {
      this.errors.push("Must have a city");
    }
    if(this.facility.address.length <= 0) {
      this.errors.push("Must have a address");
    }
    
    //If there are no errors submit the facility
    if (this.errors.length <= 0 && type=="create") {
      this.createFacility(this.facility)
    }
    else if (this.errors.length <= 0 && type=="update") {
      this.updateFacility(this.facility)
    }
  }

  createFacility(facility: FacilityModel) {
    this.facilityService.createFacility(facility).subscribe(
      data => {
        alert("successfully created the facility");
        this.router.navigate(['/dashboard/facilityManagement'])
      },
      error => {
        console.error(error);
        alert("Something went wrong when creating the facility");
      }
    );
  }

  updateFacility(facility: FacilityModel) {
    this.facilityService.updateFacility(facility).subscribe(
      data => {
        alert("successfully updated the facility");
        this.router.navigate(['/dashboard/facilityManagement'])
      },
      error => {
        console.error(error);
        alert("Something went wrong when updating the facility");
      }
    );
  }

  updateCountry(county: CountryModel) {
  	if (county == undefined) {
      this.facility.country = ""
  	} else {
  		this.facility.country = county.countryName;
  	}
  }

  updateState(state: string) {
    this.facility.state = state;
  }

  updateCity(city: string) {
    this.facility.city = city;
  }

  updateEncoder(encoder: EncoderModel) {
    if (encoder == undefined) {
      this.facility.encoder = undefined;
    } else {
      this.facility.encoder = encoder;
    }
  }

  updatePixellotUnit(pixellotUnit: PixellotModel) {
    if (pixellotUnit == undefined) {
      this.facility.pixellotUnit = undefined;
    } else {
  	  this.facility.pixellotUnit = pixellotUnit;
    }
  }

  updateStreamingAllowed(streamingAllowed: boolean) {
    this.facility.streamingAllowed = streamingAllowed;
  }

  getCountries() {
  	this.hockeytvConstantsService.getCountries(false).subscribe(
      data => {
      	this.countries = data
      },
      error => {
        console.error(error);
        alert("Something went wrong when getting countries");
      }
    );
  }

  getStates() {
    this.hockeytvConstantsService.getStates(true).subscribe(
      data => {
        this.states = data
      },
      error => {
        console.error(error);
        alert("Something went wrong when getting states");
      }
    );
  }

  getCities() {
    this.hockeytvConstantsService.getCities(true).subscribe(
      data => {
        this.cities = data
      },
      error => {
        console.error(error);
        alert("Something went wrong when getting cities");
      }
    );
  }

  getEncoders() {
  	this.facilityService.getEncoders(false).subscribe(
      data => {
      	this.encoders = data
      },
      error => {
        console.error(error);
        alert("Something went wrong when getting encoders");
      }
    );
  }

  getPixellotUnits() {
  	this.facilityService.getPixellotUnits(false).subscribe(
      data => {
      	this.pixellotUnits = data
      },
      error => {
        console.error(error);
        alert("Something went wrong when getting pixellot units");
      }
    );
  }

}
