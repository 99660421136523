import {Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';

//Services
import {FacilityService} from '../../services/facility/facility.service';
import {GameGenerationService} from '../../services/game-generation/game-generation.service';
import {GamesImportService} from '../../services/games-import/games-import-service';
import {SeasonImportService} from '../../services/season-import/season-import.service';
import {TeamsService} from '../../services/teams/teams.service';

//Models
import {FacilityModel} from '../../models/facility';
import {EncoderModel} from '../../models/encoder';
import {PixellotModel} from '../../models/pixellot';
import {GameModel} from '../../models/game';
import {LeaguestatGameModel} from '../../models/leaguestat-game';
import {LeagueModel} from '../../models/league';
import {LeaguestatSeasonsModel} from '../../models/leaguestat-season';
import {TeamModel} from '../../models/team';


@Component({
  selector: 'app-teamManagement',
  templateUrl: './games-review.component.html',
  styleUrls: ['./games-review.component.scss']
})
export class GamesReviewComponent implements OnInit {

  loaded: boolean;
  clientCode: string;
  lsSeasonId: number;
  leagueId: number;
  seasonId: number;
  games: GameModel[] = [];
  createdGames: GameModel[] = [];
  lsGames: {[id: number]: LeaguestatGameModel} = {};
  htvGames: {[id: number]: GameModel} = {};
  
  errors: string[][] = [];
  warnings: string[][] = [];

  facilities: FacilityModel[] = [];
  encoders: EncoderModel[] = [];
  pixellotUnits: PixellotModel[]= [];
  teams: TeamModel[] = [];

  timeZones: string[] = ["Canada/Eastern", "Canada/Pacific", "Canada/Mountain", "Canada/Central","Canada/Atlantic","America/Phoenix", "US/Alaska", "Canada/Saskatchewan", "Canada/Newfoundland"]


  constructor(
    private router: Router,
    private facilityService: FacilityService,
    private gameGenerationService: GameGenerationService,
    private gamesImportService: GamesImportService,
    private teamsService: TeamsService,
    private seasonImportService: SeasonImportService,
  ){}

  ngOnInit() {
    this.loaded = false;
    this.clientCode = this.seasonImportService.getClientCode();
    this.lsSeasonId = this.seasonImportService.getLSSeasonId();
    this.leagueId = this.seasonImportService.getLeagueId();
    this.lsGames = this.seasonImportService.getLSGames();
    this.htvGames = this.gamesImportService.getGames();
    this.games = [];

    this.errors = [];

    this.seasonImportService.getHockeyTvSeasonId().subscribe(
      data => {
        this.seasonId = data
        this.getLsTeams();
      },
      error => {
        console.error(error);
        alert("Failed to get the current HockeyTV season ID");
      }
    )
  }

  submitGames() {
    this.gameGenerationService.setClientCode(this.clientCode);
    var gamesForImport: GameModel[] = []
    for(let i in this.games) {
      if (this.games[i].facility.streamingAllowed == true) {
        gamesForImport.push(this.games[i]);
      }
    }
    if (this.gameGenerationService.setGames(gamesForImport)) {
      this.router.navigate(['/dashboard/generateGames']);
    } else {
      alert("Something went wrong. Please review the games and make sure all of them are okay before submitting again.");
    }
  }

  updateHomeTeam(gameId: number, team: TeamModel) {
    this.games[gameId].homeTeam = team;
  }

  updateVisitorTeam(gameId: number, team: TeamModel) {
    this.games[gameId].visitorTeam = team;
  }

  updateTimeZone(gameId: number, timeZone: string) {
    this.games[gameId].timeZone = timeZone;
  }

  convertLSGamesToHTVGames() {
    for (let key in this.lsGames) {
      var game = this.lsGames[key];
      var facility = this.facilityService.getMappedFacility(this.clientCode, game.venueId);

      var newGame = new GameModel();
      newGame.id = game.id;
      newGame.leaguestatGameId = game.gameId;
      newGame.leagueId = this.leagueId;
      newGame.seasonId = this.seasonId;
      newGame.homeTeam = this.teamsService.getMappedTeam(this.clientCode, game.homeTeamId);
      newGame.visitorTeam = this.teamsService.getMappedTeam(this.clientCode, game.visitorTeamId);
      newGame.gameDate = game.gameDate;
      newGame.gameTime = game.gameTime;
      newGame.timeZone = game.timeZone;
      newGame.gameType = game.gameType;
      newGame.event = game.event;

      newGame.facility = facility;
      if (facility == undefined) {
        newGame.encoder = undefined;
        newGame.pixellotUnit = undefined;
      } else {
        newGame.encoder = facility.encoder;
        newGame.pixellotUnit = facility.pixellotUnit;
      }

      //Verify Home Team
      if (newGame.homeTeam == undefined) {
        this.errors.push([
          "Game ID: "+game.gameId,
          "Error: Leaguestat Team \"("+game.homeTeamId+") "+(this.teamsService.getLsTeam(this.clientCode, game.homeTeamId)).name+"\" is not mapped to a HockeyTV team",
        ]);
      }
      //Verify Visitor Team
      if (newGame.visitorTeam == undefined) {
        this.errors.push([
          "Game ID: "+game.gameId,
          "Error: Leaguestat Team \"("+game.visitorTeamId+") "+(this.teamsService.getLsTeam(this.clientCode, game.visitorTeamId)).name+"\" is not mapped to a HockeyTV team",
        ]);
      }
      // Verify Facility
      if (newGame.facility == undefined) {
        this.errors.push([
          "Game ID: "+game.gameId,
          "Error: Leaguestat Venue \"("+game.venueId+") "+(this.facilityService.getVenue(this.clientCode, game.venueId)).name+"\" is not mapped to a HockeyTV facility",
        ]);
      } else {
        if (newGame.facility.encoder == undefined && newGame.facility.pixellotUnit == undefined) {
          this.warnings.push([
            "Game ID: "+game.gameId,
            "Facility: ("+newGame.facility.id+") "+newGame.facility.name,
            "Warning: There should be at least one encoder or pixellot unit attached to this facility",
          ]);
        }
      }
      //Verify Time Zone
      if (this.timeZones.indexOf(newGame.timeZone) < 0) {
        this.errors.push([
          "Game ID: "+game.gameId,
          "Error: Time zone \""+game.timeZone+"\" is not recognized, update it before continuing",
        ]);
      }

      this.games.push(newGame);
    }
    this.loaded = true;
  }

  convertUploadedHTVGames() {
    for (let key in this.htvGames) {
      var game = this.htvGames[key];
      var facility = this.facilityService.getFacility(game.facility.id);

      var newGame = new GameModel();
      newGame.id = game.id;
      newGame.leagueId = this.leagueId;
      newGame.seasonId = this.seasonId;
      newGame.homeTeam = this.teamsService.getHtvTeam(game.homeTeam.id);
      newGame.visitorTeam = this.teamsService.getHtvTeam(game.visitorTeam.id);
      newGame.gameDate = game.gameDate;
      newGame.gameTime = game.gameTime;
      newGame.timeZone = game.timeZone;
      newGame.gameType = game.gameType;
      newGame.event = game.event;

      newGame.facility = facility;
      if (facility == undefined) {
        newGame.encoder = undefined;
        newGame.pixellotUnit = undefined;
      } else {
        newGame.encoder = facility.encoder;
        newGame.pixellotUnit = facility.pixellotUnit;
      }

      //Verify Home Team
      if (newGame.homeTeam == undefined) {
        this.errors.push([
          "Game ID: "+game.id,
          "Error: HTV Team "+game.homeTeam.id+" was not found",
        ]);
      }
      //Verify Visitor Team
      if (newGame.visitorTeam == undefined) {
        this.errors.push([
          "Game ID: "+game.id,
          "Error: HTV Team "+game.visitorTeam.id+" was not found",
        ]);
      }
      // Verify Facility
      if (newGame.facility == undefined) {
        this.errors.push([
          "Game ID: "+game.id,
          "Error: HTV Facility "+game.facility.id+" was not found",
        ]);
      } else {
        if (newGame.facility.encoder == undefined && newGame.facility.pixellotUnit == undefined) {
          this.warnings.push([
            "Game ID: "+game.id,
            "Facility: ("+newGame.facility.id+") "+newGame.facility.name,
            "Warning: There should be at least one encoder or pixellot unit attached to this facility",
          ]);
        }
      }
      //Verify Time Zone
      if (this.timeZones.indexOf(newGame.timeZone) < 0) {
        this.errors.push([
          "Game ID: "+game.id,
          "Error: Time zone \""+game.timeZone+"\" is not recognized, update it before continuing",
        ]);
      }

      this.games.push(newGame);
    }
    this.loaded = true;
  }

  getGameByLeaguestatGameId() {
    return true;
  }

   getLsTeams() {
    this.teamsService.getLSTeams(true, this.clientCode).subscribe(
      data => {
        this.getHtvTeams();
      },
      error => {
        console.error(error);
        alert("Failed to get list of teams");
      }
    );
  }

  getHtvTeams() {
    this.teamsService.getHTVTeams(true).subscribe(
      data => {
        this.teams = data;
        this.getLinkedTeams();
      },
      error => {
        console.error(error);
        alert("Failed to get list of teams");
      }
    );
  } 

  getLinkedTeams() {
    this.teamsService.getLinkedTeams(true, this.clientCode).subscribe(
      data => {
        this.getVenues();
      },
      error => {
        console.error(error);
        alert("Failed to get list of linked teams");
      }
    );
  }

  getVenues() {
    this.facilityService.getVenues(true, this.clientCode).subscribe(
      data => {
        this.getFacilities();
      },
      error => {
        console.error(error);
        alert("Failed to get list of arenas");
      }
    );
  }

  getFacilities() {
    this.facilityService.getFacilities(true).subscribe(
      data => {
        this.facilities = data;
        this.getLinkedFacilities();
      },
      error => {
        console.error(error);
        alert("Failed to get list of arenas");
      }
    );
  }

  getLinkedFacilities() {
    this.facilityService.getLinkedFacilities(true, this.clientCode).subscribe(
      data => {
        this.getEncoders();
      },
      error => {
        console.error(error);
        alert("Failed to get list of linked facilities");
      }
    );
  }

  getEncoders() {
    this.facilityService.getEncoders(true).subscribe(
      data => {
        this.encoders = data;
        this.getPixellotUnits();
      },
      error => {
        console.error(error);
        alert("Failed to get list of encoders");
      }
    );
  }

  getPixellotUnits() {
    this.facilityService.getPixellotUnits(true).subscribe(
      data => {
        this.pixellotUnits = data;
        this.getCreatedGames();
      },
      error => {
        console.error(error);
        alert("Failed to get list of pixellot units");
      }
    );
  }

  getCreatedGames() {
    this.gameGenerationService.getUpcomingLeaguestatGamesBySeason(true, this.clientCode, this.seasonId).subscribe(
      data => {
        this.createdGames = data;
        this.convertLSGamesToHTVGames();
      },
      error => {
        console.error(error);
        alert("Failed to get list of created games");
      }
    );
  }
}
