import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[auto-focus]'
})
export class AutoFocusDirective {

  element: ElementRef 

  constructor(element: ElementRef) {
  	this.element = element;
  }

  ngAfterContentInit() {
  	this.element.nativeElement.focus();
  }

}
