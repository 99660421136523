interface IClientVenueFacilityLinkModel {
  clientCode: string;
  venueId: number;
  facilityId: number;
}

export class ClientVenueFacilityLinkModel {
  public clientCode: string;
  public venueId: number;
  public facilityId: number;
    
  constructor();
  constructor(obj: IClientVenueFacilityLinkModel);
  constructor(obj?: any){
    this.clientCode = obj && obj.clientCode || "";
    this.venueId = obj && obj.venueId || 0;
    this.facilityId = obj && obj.facilityId || 0;
  }
}
