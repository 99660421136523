import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

//Environment
import {environment} from '../../../environments/environment';

//Services
import {Req} from '../request/request.service';

//Models
import {TeamModel} from '../../models/team';
import {ClientTeamLinkModel} from '../../models/client-team-link';



@Injectable({
  providedIn: 'root'
})

export class TeamsService {

  mappedLsTeams: {[clientCode: string]: {[id: number]: TeamModel}} = {};
  mappedHtvTeams: {[id: number]: TeamModel} = undefined;
  mappedLinkedTeams: {[clientCode: string]: ClientTeamLinkModel[]} = {};

  constructor(
    private req: Req,
   ){}

  public createTeamArray(mappedTeams: {[id: number]: TeamModel}): TeamModel[] {
    let teams: TeamModel[] = [];
    for (let key in mappedTeams) {
      teams.push(mappedTeams[key]);
    }
    return teams;
  }

  public getLSTeams(forceUpdate: boolean, clientCode: string) {
    if (this.mappedLsTeams[clientCode] != undefined && forceUpdate == false) {
      return Observable.create((observer) => {observer.next();}).pipe(map((response: any) => {return this.createTeamArray(this.mappedLsTeams[clientCode]);}));
    } else {
      return this.req.get("/leaguestat/"+clientCode+"/teams",{API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')}).pipe(map((response: any) => {
        this.mappedLsTeams[clientCode] = {};
        if (response && response.data) {
          for (var i in response.data) {
            if (response.data[i].active == 1) {
              var team = new TeamModel()
              team.id = response.data[i].id;
              team.name = response.data[i].name;
              team.leagueName = response.data[i].league_name;

              this.mappedLsTeams[clientCode][team.id] = team;
            }
          }
        }
        return this.createTeamArray(this.mappedLsTeams[clientCode]);
      }));
    }
  }

  public getHTVTeams(forceUpdate: boolean) {
    if (this.mappedHtvTeams != undefined && forceUpdate == false) {
      return Observable.create((observer) => {observer.next();}).pipe(map((response: any) => {return this.createTeamArray(this.mappedHtvTeams);}));
    } else {
      return this.req.get("/htv/teams",{API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')}).pipe(map((response: any) => {
        this.mappedHtvTeams = {};
        if (response && response.data) {
          for (var i in response.data) {
            var team = new TeamModel();
            team.id = response.data[i].id;
            team.name = response.data[i].name + " " + response.data[i].mascot;
            team.leagueName = response.data[i].leagueName;

            this.mappedHtvTeams[team.id] = team;
          }
        }
        return this.createTeamArray(this.mappedHtvTeams);
      }));
    }
  }

  public getLinkedTeams(forceUpdate: boolean, clientCode: string) {
    if (this.mappedLinkedTeams[clientCode] != undefined && forceUpdate == false) {
      return Observable.create((observer) => {observer.next();}).pipe(map((response: any) => {return this.mappedLinkedTeams[clientCode];}));
    } else {
      return this.req.get("/htv/teams/" + clientCode, {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')}).pipe(map((response: any) => {
        this.mappedLinkedTeams[clientCode] = [];
        if (response && response.data) {
          for (var i in response.data) {
            var linkedTeam = new ClientTeamLinkModel();
            linkedTeam.clientCode = response.data[i].clientCode;
            linkedTeam.lsTeamId = response.data[i].leaguestatTeamId;
            linkedTeam.htvTeamId = response.data[i].htvTeamId;

            this.mappedLinkedTeams[clientCode].push(linkedTeam);
          }
        }
        return this.mappedLinkedTeams[clientCode];
      }));
    }
  }

  public updateTeamLink(clientCode: string, lsTeamId: number, htvTeamId: number) {
    return this.req.post("/leaguestat/"+clientCode+"/link/team",{clientCode:clientCode, leaguestatTeamId: lsTeamId, htvTeamId: htvTeamId}, {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')});
  }

  public removeTeamLink(clientCode: string, lsTeamId: number) {
    return this.req.delete("/leaguestat/"+clientCode+"/link/team/"+lsTeamId, {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')});
  }

  public getMappedTeam(clientCode: string, teamId: number) {
    for (var i=0; i < this.mappedLinkedTeams[clientCode].length; i++) {
      if (this.mappedLinkedTeams[clientCode][i].lsTeamId == teamId) {
        return this.mappedHtvTeams[this.mappedLinkedTeams[clientCode][i].htvTeamId];
      }
    }
    return undefined;
  }

  public getLsTeam(clientCode: string, teamId: number) {
    if (this.mappedLsTeams[clientCode][teamId] != undefined) {
      return this.mappedLsTeams[clientCode][teamId];
    } else {
      return new TeamModel();
    }
  }

  public getHtvTeam(teamId: number) {
    if (this.mappedHtvTeams[teamId] != undefined) {
      return this.mappedHtvTeams[teamId];
    } else {
      return new TeamModel();
    }
  }
}
