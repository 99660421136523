interface ILeaguestatSeasonsModel {
  id: number;
  name: string;
}

export class LeaguestatSeasonsModel {
  public id: number;
  public name: string;

  constructor();
  constructor(obj: ILeaguestatSeasonsModel);
  constructor(obj?: any){
    this.id = obj && obj.id || 0;
    this.name = obj && obj.name || "";
  }
}
