import {Component, OnInit} from '@angular/core';

//Services
import {FacilityService} from '../../services/facility/facility.service';

//Models
import {EncoderModel} from '../../models/encoder';
import {PixellotModel} from '../../models/pixellot';
import {FacilityModel} from '../../models/facility';
import {LinkedEncoderModel} from '../../models/linked-encoder';

@Component({
  selector: 'app-teamManagement',
  templateUrl: './facility-management.component.html',
  styleUrls: ['./facility-management.component.scss']
})
export class FacilityManagementComponent implements OnInit {

  loaded: boolean
  encoders: EncoderModel[] = [];
  pixellotUnits: PixellotModel[] = [];
  facilities: FacilityModel[] = [];

  filteredFacilitiesSearchText: string = "";
  filteredFacilities: FacilityModel[] = [];

  constructor(
    private facilityService: FacilityService,
  ){}

  ngOnInit() {
    this.loaded = false;
    this.getEncoders();
  }

  linkedFacilitiesFilterSearch(search: string, facilities: FacilityModel[]): FacilityModel[] {
    this.filteredFacilitiesSearchText = search;
    search = search.replace(/^[ ]+|[ ]+$/g,'');
    var filteredFacilities = facilities;
    if (search.length > 0) {
      filteredFacilities = [];
      for (var i=0; i < facilities.length; i++) {
        let facilityValue = (facilities[i].id+" "+facilities[i].name+" "+facilities[i].city+" "+facilities[i].country).toLowerCase();
        if(facilityValue.includes(search.toLowerCase())) {
          filteredFacilities.push(facilities[i]);
        }
      }
    }
    return filteredFacilities;
  }

  getEncoders() {
    // Get the HTV Encoders
    this.facilityService.getEncoders(false).subscribe(
      data => {
        this.encoders = data;
        this.GetPixellotUnits();
      },
      error => {
        console.error(error);
        alert("Failed to get encoders");
      }
    );
  }

    GetPixellotUnits() {
    // Get the HTV Pixellot Units
    this.facilityService.getPixellotUnits(false).subscribe(
      data => {
        this.pixellotUnits = data;
        this.getFacilities();
      },
      error => {
        console.error(error);
        alert("Failed to get pixellot units");
      }
    );
  }

  getFacilities() {
    // Get the HTV arenas
    this.facilityService.getFacilities(false).subscribe(
      data => {
        this.facilities = data;
        this.filteredFacilities = this.linkedFacilitiesFilterSearch(this.filteredFacilitiesSearchText, this.facilities);
        this.loaded = true;
      },
      error => {
        console.error(error);
        alert("Failed to get facilities");
      },
    );
  }
}
