interface ILoginRequest {
  email: string;
  password: string;
  appId: string;
  appKey: string;
}

export class LoginRequest {
  public email: string;
  public password: string;
  public appId: string;
  public appKey: string;

  constructor();
  constructor(obj: ILoginRequest);
  constructor(obj?: any){
    this.email = obj && obj.email || "";
    this.password = obj && obj.password || "";
    this.appId = obj && obj.appId || (Math.floor((Math.random() * 100000) + 1) + '.' + Date.now()/1000);
    this.appKey = obj && obj.appKey || "liurwea7843q2hb4278431290r32oi";
  }
}
