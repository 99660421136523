import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

//Environment
import {environment} from '../../../environments/environment';

//Services
import {Req} from '../request/request.service';

//Models
import {EventModel} from '../../models/event';
import {GameModel} from '../../models/game';
import {LeaguestatGameModel} from '../../models/leaguestat-game';
import {GameTypeModel} from '../../models/game-type';


@Injectable({
  providedIn: 'root'
})

export class SeasonImportService {

  clientCode: string;
  leagueId: number;
  lsSeasonId: number;
  htvSeasonId: number;
  lsGames: {[id: number]: LeaguestatGameModel} = undefined;

  constructor(
    private http: HttpClient,
    private req: Req,
  ){}

  public getUpcomingLeaguestatSeasonGames(clientCode: string, leagueId:number, seasonId: number, event: EventModel, gameType: GameTypeModel) {
    this.clientCode = clientCode;
    this.leagueId = leagueId;
    this.lsSeasonId = seasonId;

    return this.req.get("/leaguestat/" + clientCode + "/upcomingGames/" + seasonId, {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')}).pipe(map((response: any ) => {
      this.lsGames = {};
      if (response && response.data) {
        for (var i=0; i < response.data.length; i++) {
          var game = new LeaguestatGameModel();
          game.id = i;
          game.clientCode = clientCode;
          game.gameId = response.data[i].Id
          game.seasonId = response.data[i].SeasonId,
          game.homeTeamId = response.data[i].HomeTeam.Id;
          game.visitorTeamId = response.data[i].VisitingTeam.Id;
          game.venueId = response.data[i].Location.Id;
          game.gameDate = response.data[i].DatePlayed;
          game.gameTime = response.data[i].ScheduleTime;
          game.timeZone = response.data[i].Timezone;
          game.gameType = gameType;
          game.event = event;

          this.lsGames[game.gameId] = game;
        }
      }
      return this.lsGames;
    }));
  }

  public getHockeyTvSeasonId() {
    return this.http.get<any>(environment.htvApiUrl+"/current_season", {}).pipe(map((response: any ) => {
      var seasonId: number = 0;
      if (response && response.seasonId) {
        seasonId = response.seasonId;
      }
      seasonId = 30; //TODO: Remove the hardcoded SeasonID when switch to 29 in the htv-api
      this.htvSeasonId = 30; //TODO: Remove the hardcoded SeasonID when switch to 29 in the htv-api
      return seasonId;
    }));
  }

  public getClientCode(): string {
    return this.clientCode;
  }

  public getLeagueId(): number {
    return this.leagueId;
  }

  public getLSSeasonId(): number {
    return this.lsSeasonId;
  }

  public getLSGames() {
    return this.lsGames;
  }

  public getLSGame(id: number): LeaguestatGameModel {
    return this.lsGames[id];
  }

}
