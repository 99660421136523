interface IClientTeamLinkModel {
  clientCode: string;
  lsTeamId: number;
  htvTeamId: number;
}

export class ClientTeamLinkModel {
  public clientCode: string;
  public lsTeamId: number;
  public htvTeamId: number;
  
  constructor();
  constructor(obj: IClientTeamLinkModel);
  constructor(obj?: any){
    this.clientCode = obj && obj.clientCode || "";
    this.lsTeamId = obj && obj.lsTeamId || 0;
    this.htvTeamId = obj && obj.htvTeamId || 0;
  }
}
