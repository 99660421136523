import {NgModule} from '@angular/core';
import {APP_BASE_HREF} from '@angular/common';
import {Routes, RouterModule} from '@angular/router';

//Components
import {ImportGamesComponent} from './components/import-games/import-games.component'
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {LSTeamsManagementComponent} from './components/ls-teams-management/ls-teams-management.component';
import {LSVenuesManagementComponent} from './components/ls-venues-management/ls-venues-management.component';
import {FacilityManagementComponent} from './components/facility-management/facility-management.component';
import {FacilityViewComponent} from './components/facility-management/facility-view/facility-view.component';
import {LoginComponent} from './components/login/login.component';
import {AuthGuard} from './guards/auth/auth.guard';
import {GamesReviewComponent} from './components/games-review/games-review.component';
import {GeneratingGamesComponent} from './components/generating-games/generating-games.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'importGames',
        component: ImportGamesComponent,
      },
      {
        path: 'createFacility',
        component: FacilityViewComponent,
      },
      {
        path: 'editFacility/:facility_id',
        component: FacilityViewComponent,
      },
      {
        path: 'lsTeamsManagement',
        component: LSTeamsManagementComponent,
      },
      {
        path: 'lsVenuesManagement',
        component: LSVenuesManagementComponent,
      },
      {
        path: 'facilityManagement',
        component: FacilityManagementComponent,
      },
      {
        path: 'gamesReview/:review_type',
        component: GamesReviewComponent,
      },
      {
        path: 'generateGames',
        component: GeneratingGamesComponent,
      },
    ]
  },
  {
    path: '**',
    component: LoginComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
