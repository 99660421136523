import { Injectable } from '@angular/core';
import * as moment from 'moment'

//Models
import {GameModel} from '../../models/game'

@Injectable({
  providedIn: 'root'
})
export class GamesImportService {
  public games: {[id: number]: GameModel} = undefined;

  constructor(

  ){}

  setGames(games: GameModel[]): void {
    this.games = {};
    for (let i=0; i<games.length; i++) {
      this.games[i] = games[i];
    }
    return;
  }

  getGames(): {[id:number]: GameModel} {
    return this.games
  }

}
