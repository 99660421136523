import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

//Services
import {Req} from '../request/request.service';

//Models
import {LeagueModel} from '../../models/league';
import {GameTypeModel} from '../../models/game-type';
import {CountryModel} from '../../models/country';

@Injectable({
  providedIn: 'root'
})
export class HockeytvConstantsService {

  mappedLeagues: {[id:number]: LeagueModel} = undefined;
  mappedGameTypes: {[id:number]: GameTypeModel} = undefined;
  mappedCountries: {[id: string]: CountryModel} = undefined;
  states: string[] = [];
  cities: string[] = [];

  constructor(
    private req: Req,
   ){}

  private createLeaguesArray(mappedLeagues: {[id:number]: LeagueModel}): LeagueModel[] {
    var leagues: LeagueModel[] = [];
    for (var key in mappedLeagues) {
      leagues.push(mappedLeagues[key]);
    }
    return leagues;
  }

  private createGameTypesArray(mappedGameTypes: {[id:number]: GameTypeModel}): GameTypeModel[] {
    var gameTypes: GameTypeModel[] = [];
    for (var key in mappedGameTypes) {
      gameTypes.push(mappedGameTypes[key]);
    }
    return gameTypes;
  }

  private createCountriesArray(mappedCountries: {[id:string]: CountryModel}): CountryModel[] {
    var countries: CountryModel[] = [];
    for (var key in mappedCountries) {
      countries.push(mappedCountries[key]);
    }
    return countries;
  }

  public getLeagues(forceUpdate: boolean) {
    if (this.mappedLeagues != undefined && forceUpdate == false) {
      return Observable.create((observer) => {observer.next();}).pipe(map((response: any) => {return this.createLeaguesArray(this.mappedLeagues)}));
    } else {
      return this.req.get("/htv/leagues", {API_KEY: localStorage.getItem('currentUser')}).pipe(map((response: any) => {
        this.mappedLeagues = {};
        if (response && response.data) {
          for (var i in response.data) {
            var league = new LeagueModel();
            league.id = response.data[i].league_id;
            league.name =response.data[i].league_name;

            this.mappedLeagues[league.id] = league;
          }
        }
        return this.createLeaguesArray(this.mappedLeagues);
      }));
    }
  }

  public getGameTypes(forceUpdate: boolean) {
    if (this.mappedGameTypes != undefined && forceUpdate == false) {
      return Observable.create((observer) => {observer.next();}).pipe(map((response: any) => {return this.createGameTypesArray(this.mappedGameTypes)}));
    } else {
      return this.req.get("/htv/gameTypes", {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')}).pipe(map((response: any) => {
        this.mappedGameTypes = {};
        if (response && response.data) {
          for (var i in response.data) {
            var gameType = new GameTypeModel();
            gameType.id = response.data[i].gameTypeId;
            gameType.name =response.data[i].gameTypeName;

            this.mappedGameTypes[gameType.id] = gameType;
          }
        }
        return this.createGameTypesArray(this.mappedGameTypes);
      }));
    }
  }

  public getCountries(forceUpdate: boolean) {
    if (this.mappedCountries != undefined && forceUpdate == false) {
      return Observable.create((observer) => {observer.next();}).pipe(map((response: any) => {return this.createCountriesArray(this.mappedCountries)}));
    } else {
      return this.req.get("/htv/countries", {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')}).pipe(map((response: any) => {
        this.mappedCountries = {};
        if (response && response.data) {
          for (var i in response.data) {
            var country = new CountryModel();
            country.countryCode = response.data[i].countryCode;
            country.countryName = response.data[i].countryName;

            this.mappedCountries[country.countryCode] = country;
          }
        }
        return this.createCountriesArray(this.mappedCountries);
      }));
    }
  }

  public getStates(forceUpdate: boolean) {
    if (this.states.length > 0 && forceUpdate == false) {
      return Observable.create((observer) => {observer.next();}).pipe(map((response: any) => {return this.states}));
    } else {
      return this.req.get("/htv/states", {API_KEY: localStorage.getItem('currentUser')}).pipe(map((response: any) => {
        this.states = [];
        if (response && response.data) {
          for (var i in response.data) {
            this.states.push(response.data[i]);
          }
        }
        return this.states;
      }));
    }
  }

  public getCities(forceUpdate: boolean) {
    if (this.cities.length > 0 && forceUpdate == false) {
      return Observable.create((observer) => {observer.next();}).pipe(map((response: any) => {return this.cities}));
    } else {
      return this.req.get("/htv/cities", {API_KEY: localStorage.getItem('currentUser')}).pipe(map((response: any) => {
        this.cities = [];
        if (response && response.data) {
          for (var i in response.data) {
            this.cities.push(response.data[i]);
          }
        }
        return this.cities;
      }));
    }
  }

  public getGameType(id: number): GameTypeModel {
    return this.mappedGameTypes[id];
  }

  public getCountry(id: string): CountryModel {
    return this.mappedCountries[id];
  }
}
