interface IVenueModel {
  id: number;
  name: string;
  location: string;
}

export class VenueModel {
  public id: number;
  public name: string;
  public location: string;

  constructor();
  constructor(obj: IVenueModel);
  constructor(obj?: any){
    this.id = obj && obj.id || 0;
    this.name = obj && obj.name || "";
    this.location = obj && obj.location || "";
  }
}
