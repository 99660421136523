import {FacilityModel} from './facility';
import {VenueModel} from './venue';

interface ILinkedFacilityModel {
  venue: VenueModel;
  facility: FacilityModel;
}

export class LinkedFacilityModel {
  public venue: VenueModel;
  public facility: FacilityModel;

  constructor();
  constructor(obj: ILinkedFacilityModel);
  constructor(obj?: any){
    this.venue = obj && obj.venue || undefined;
    this.facility = obj && obj.facility || undefined;
  }
}
