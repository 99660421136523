interface IEncoderModel {
  id: number;
  name: string;
}

export class EncoderModel {
  public id: number;
  public name: string;

  constructor();
  constructor(obj: IEncoderModel);
  constructor(obj?: any) {
    this.id = obj && obj.id || 0;
    this.name = obj && obj.name || "";
  }
}
