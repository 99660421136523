interface ITeamModel {
  id: number;
  name: string;
  leagueName: string;
}

export class TeamModel {
  public id: number;
  public name: string;
  public leagueName: string;

  constructor();
  constructor(obj: ITeamModel);
  constructor(obj?: any){
    this.id = obj && obj.id || 0;
    this.name = obj && obj.name || "";
    this.leagueName = obj && obj.leagueName || "";
  }
}
