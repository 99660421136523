import {Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import * as moment from 'moment';

//Services
import {EventsService} from '../../services/events/events.service';
import {GameGenerationService} from '../../services/game-generation/game-generation.service';
import {GamesImportService} from '../../services/games-import/games-import-service';
import {LeaguestatService} from '../../services/leaguestat/leaguestat.service';
import {SeasonImportService} from '../../services/season-import/season-import.service';
import {HockeytvConstantsService} from '../../services/hockeytv-constants/hockeytv-constants.service';

//Models
import {EventModel} from '../../models/event';
import {FacilityModel} from '../../models/facility';
import {GameModel} from '../../models/game';
import {GameTypeModel} from '../../models/game-type';
import {LeaguestatSeasonsModel} from '../../models/leaguestat-season';
import {LeagueModel} from '../../models/league';
import {TeamModel} from '../../models/team';


@Component({
  selector: 'app-teamManagement',
  templateUrl: './import-games.component.html',
  styleUrls: ['./import-games.component.scss']
})
export class ImportGamesComponent implements OnInit {

  loaded: boolean;
  lsClients: string[] = [];
  seasons: LeaguestatSeasonsModel[] = [];
  allLeagues: LeagueModel[] = [];
  leagues: LeagueModel[] = [];
  events: EventModel[] = [];
  gameTypes: GameTypeModel[] = [];
  currentClient : string;
  lsImportSeasonId: number = 0;
  lsImportLeagueId: number = 0;
  lsImportEvent: EventModel;
  lsImportGameType: GameTypeModel;

  gamesImportgames: GameModel[] = [];
  gamesImportLeagueId: number = 0;
  gamesImportGameType: GameTypeModel;

  constructor(
    private router: Router,
    private eventsService: EventsService,
    private gameGenerationService: GameGenerationService,
    private gamesImportService: GamesImportService,
    private hockeytvConstantsService: HockeytvConstantsService,
    private leaguestatService: LeaguestatService,
    private seasonImportService: SeasonImportService,
  ){}

  ngOnInit() {
    this.loaded = false;
    this.lsImportSeasonId = 0;
    this.lsImportLeagueId = 0;
    this.lsImportEvent = undefined;
    this.lsImportGameType = undefined;
    this.leaguestatService.getLSHTVClients(false).subscribe(
      data => {
        this.lsClients = data;
        this.currentClient = this.lsClients[0];
        this.getSeasons(this.currentClient);
        this.getHTVLeagues();
        this.getLSLeagues(this.currentClient);
        this.getEvents();
        this.getGameTypes();
      },
      error => {
        alert("Failed to get HTV Leageuestat Clients");
      }
    );
  }

  updateClientCode(clientCode: string) {
    //Update client and then clear out old data before getting new data.
    this.loaded = false;
    this.currentClient = clientCode;
    this.lsImportSeasonId = 0;
    this.lsImportLeagueId = 0;
    this.seasons = [];
    this.leagues = [];
    this.getSeasons(clientCode);
    this.getLSLeagues(clientCode);
  }

  updateLsImportSeason(season: LeaguestatSeasonsModel) {
    if (season != undefined) {
      this.lsImportSeasonId = season.id;
    } else {
      this.lsImportSeasonId = 0;
    }
  }

  updateLsImportLeague(league: LeagueModel) {
    if (league != undefined) {
      this.lsImportLeagueId = league.id;
    } else {
      this.lsImportLeagueId = 0;
    }
  }

  updateLsImportEvent(event: EventModel) {
    if (event != undefined) {
      this.lsImportEvent = event;
    } else {
      this.lsImportEvent = undefined;
    }
  }

  updateLsImportGameType(gameType: GameTypeModel) {
    if (gameType != undefined) {
      this.lsImportGameType = gameType;
    } else {
      this.lsImportGameType = undefined;
    }
  }

  importLsSeason() {
    if (this.lsImportSeasonId <= 0) {
      alert("You must select a season");
      return;
    }
    if (this.lsImportLeagueId <= 0) {
      alert("You must select a HockeyTV League");
      return;
    }
    if (this.lsImportGameType == undefined) {
      alert("You must select a Game Type for the import");
      return;
    }

    this.seasonImportService.getUpcomingLeaguestatSeasonGames(this.currentClient, this.lsImportLeagueId, this.lsImportSeasonId, this.lsImportEvent, this.lsImportGameType).subscribe(
      data => {
        this.router.navigate(['/dashboard/gamesReview/leaguestat_games'])
      },
      error => { 
        alert("Failed to get a list of games for the provided client code and season");
      }
    );
  }

  verifyFileUpload(event: any) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsText(file);
      reader.onload = () => {
        var lines: string[] = [];
        for (let line of reader.result.split(/\r?\n|\r/g)) {
          lines.push(line);
        }
        // get and set the index value row. Then remove 
        let indexValues = lines[0].split(',');
        lines.splice(0,1);

        var games: GameModel[] = [];
        for (let line of lines) {
          // Setup the blank values needed.
          let game = new GameModel();
          game.facility = new FacilityModel();
          game.homeTeam = new TeamModel();
          game.visitorTeam = new TeamModel();

          let values  = line.split(',');
          for (let i=0; i<values.length; i++) {
            if (indexValues[i] == "game_date") {
              var date = moment(values[i], 'YYYY-MM-DD');
              if (date.isValid()) {
                game.gameDate = date.format('YYYY-MM-DD')
              } else {
                alert(indexValues[i]+" is not a valid date. Expected Format YYYY-MM-DD");
                return;
              }
            } else if (indexValues[i] == "game_time") {
              var time = moment(values[i], 'hh:mm:ss');
              if (time.isValid()) {
                game.gameTime = time.format('hh:mm:ss')
              } else {
                alert(indexValues[i]+" is not a valid time. Expected Format hh:mm:ss");
                return;
              }
            } else if (indexValues[i] == "timezone") {
              game.timeZone = values[i];
            } else if (indexValues[i] == "facility") {
              game.facility.id = +values[i];
            } else if (indexValues[i] == "visitor_team") {
              game.visitorTeam.id = +values[i];
            } else if (indexValues[i] == "home_team") {
              game.homeTeam.id = +values[i];
            } else if (indexValues[i] == "event" && +values[i] > 0) {
              let event = this.eventsService.getEvent(+values[i])
              if (event != undefined) {
                game.event = event;
              } else {
                alert(indexValues[i]+" is not a valid event ID");
                return;
              }
            }
            else {
              alert(indexValues[i]+" is not a valid index value. Make sure the file has enough top row index values and that they line up appropriately.");
              return;
            }
          }
          games.push(game);
        }
        this.gamesImportgames = games;
      }
    }
  }

  updateImportLeague(league: LeagueModel) {
    if (league != undefined) {
      this.gamesImportLeagueId = league.id;
    } else {
      this.gamesImportLeagueId = 0;
    }
  }

  updateImportGameType(gameType: GameTypeModel) {
    if (gameType != undefined) {
      this.gamesImportGameType = gameType;
    } else {
      this.gamesImportGameType = undefined;
    }
  }

  importGames() {
    if (this.gamesImportLeagueId == 0) {
      alert("You must select a League for the import");
      return;
    }
    if (this.gamesImportGameType == undefined) {
      alert("You must select a Game Type for the import");
      return;
    }

    this.gamesImportService.setGames(this.gamesImportgames);
    this.router.navigate(['/dashboard/gamesReview/htv_games']);
  }

  getSeasons(clientCode: string) {
    this.leaguestatService.getSeasons(true, clientCode).subscribe(
      data => {
        this.seasons = data;
        this.loaded = true;
      },
      error => { 
        alert("Failed to get list of seasons for current client");
        this.loaded = true;
      }
    );
  }

  getHTVLeagues() {
    this.hockeytvConstantsService.getLeagues(true).subscribe(
      data => {
        this.allLeagues = data;
      },
      error => { 
        alert("Failed to get list of leagues from HTV");
      }
    );
  }

  getLSLeagues(clientcode: string) {
    this.leaguestatService.getLeagues(true, this.currentClient).subscribe(
      data => {
        this.leagues = data;
      },
      error => { 
        alert("Failed to get list of leagues for current client");
      }
    );
  }

  getEvents() {
    this.eventsService.getEvents(true).subscribe(
      data => {
        this.events = data;
      },
      error => {
        alert("failed to get a the list of events");
      }
    );
  }

  getGameTypes() {
    this.hockeytvConstantsService.getGameTypes(true).subscribe(
      data => {
        this.gameTypes = data;
      },
      error => {
        alert("failed to get a the list of game types");
      }
    );
  }
}
