import {EncoderModel} from './encoder';
import {PixellotModel} from './pixellot';

interface IFacilityModel {
    id: number;
    name: string;
    country: string;
    state: string;
    city: string;
    address: string;
    zip: string;
    phone: string;
    encoder: EncoderModel;
    pixellotUnit: PixellotModel;
    streamingAllowed: boolean;
}

export class FacilityModel {
  public id: number;
  public name: string;
  public country: string;
  public state: string;
  public city: string;
  public address: string;
  public zip: string;
  public phone: string;
  public encoder: EncoderModel;
  public pixellotUnit: PixellotModel;
  public streamingAllowed: boolean;

  constructor();
  constructor(obj: IFacilityModel);
  constructor(obj?: any){
    this.id = obj && obj.id || 0;
    this.name = obj && obj.name || "";
    this.country = obj && obj.country || "";
    this.state = obj && obj.state || "";
    this.city = obj && obj.city || "";
    this.address = obj && obj.address || "";
    this.zip = obj && obj.zip || "";
    this.phone = obj && obj.phone || "";
    this.encoder = obj && obj.encoder || undefined;
    this.pixellotUnit = obj && obj.pixellotUnit || undefined;
    this.streamingAllowed = obj && obj.streamingAllowed || true;
  }
}
