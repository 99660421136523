import {Component, OnInit} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication/authentication.service';
import {LoginRequest} from '../../../models/login-request';


@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  loading: boolean;
  model: LoginRequest;
  returnUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.authenticationService.logout();
    this.loading = false;
    this.model = new LoginRequest();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
  }

  onSubmit() {
    this.loading = true;
    this.authenticationService.login(this.model.email, this.model.password, this.model.appId, this.model.appKey).subscribe(
      data => {
        this.authenticationService.checkAdmin().subscribe(
          data => {
            this.router.navigate([this.returnUrl]);
          }, error => {
            this.loading = false;
          });
      }, error => {
        this.loading = false;
      });
  }
}
