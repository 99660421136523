interface IPixellotModel {
  id: number;
  name: string;
  venue: string;
  clubId: number;
  clubName: string;
}

export class PixellotModel {
  public id: number;
  public name: string;
  public venue: string;
  public clubId: number;
  public clubName: string;

  constructor();
  constructor(obj: IPixellotModel);
  constructor(obj?: any){
    this.id = obj && obj.id || "";
    this.name = obj && obj.name || "";
    this.venue = obj && obj.venue || "";
    this.clubId = obj && obj.clubId || "";
    this.clubName = obj && obj.clubName || "";
  }
}
