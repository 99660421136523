import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

//Environment
import {environment} from '../../../environments/environment';

//Services
import {Req} from '../request/request.service';

//Models
import {LeaguestatSeasonsModel} from '../../models/leaguestat-season';
import {LeagueModel} from '../../models/league'


@Injectable({
  providedIn: 'root'
})

export class LeaguestatService {

  LSHTVClients: string[] = [];
  seasons: {[clientCode: string]: LeaguestatSeasonsModel[]} = {};
  leagues: {[clientCode: string]: LeagueModel[]} = {};

  constructor(
    private req: Req,
   ){}

  public getLSHTVClients(forceUpdate: boolean) {
    if (this.LSHTVClients.length > 0 && forceUpdate == false) {
      return Observable.create((observer) => {observer.next();}).pipe(map((response: any) => {return this.LSHTVClients;}));
    } else {
      return this.req.get("/leaguestatClients", {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')}).pipe(map((response: any ) => {
        this.LSHTVClients = [];
        if (response && response.data) {
          for (var i in response.data) {
            this.LSHTVClients.push(response.data[i]);
          }
        }
        return this.LSHTVClients;
      }));
    }
  }

  public getSeasons(forceUpdate: boolean, clientCode: string) {
    if (this.seasons[clientCode] != undefined && forceUpdate == false) {
      return Observable.create((observer) => {observer.next();}).pipe(map((response: any) => {return this.seasons[clientCode];}));
    } else {
      return this.req.get("/leaguestat/" + clientCode + "/seasons", {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')}).pipe(map((response: any ) => {
        this.seasons[clientCode] = [];
        if (response && response.data) {
          for (var i in response.data) {
            this.seasons[clientCode].push(new LeaguestatSeasonsModel({id: response.data[i].Id, name: response.data[i].LongName}));
          }
        }
        return this.seasons[clientCode];
      }));
    }
  }

  public getLeagues(forceUpdate: boolean, clientCode: string) {
    if (this.leagues[clientCode] != undefined && forceUpdate == false) {
      return Observable.create((observer) => {observer.next();}).pipe(map((response: any) => {return this.leagues[clientCode];}));
    } else {
      return this.req.get("/htv/leaguestat/" + clientCode + "/leagues", {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')}).pipe(map((response: any ) => {
        this.leagues[clientCode] = [];
        if (response && response.data) {
          for (var i in response.data) {
            var league = new LeagueModel();
            league.id = response.data[i].league_id;
            league.name = response.data[i].league_name;
            this.leagues[clientCode].push(league);
          }
        }
        return this.leagues[clientCode];
      }));
    }
  }
}
