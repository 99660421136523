import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

//Services
import {Req} from '../request/request.service';

//Models
import {EventModel} from '../../models/event'


@Injectable({
  providedIn: 'root'
})
export class EventsService {

	mappedEvents: {[id: number]: EventModel} = undefined;
	mappedUpcomingEvents: {[id: number]: EventModel} = undefined;

  constructor(
  	private req: Req,
  ){}

  private createEventsArray(mappedEvents: {[id: number]: EventModel}): EventModel[] {
    let events: EventModel[] = [];
    for (let key in mappedEvents) {
      events.push(mappedEvents[key]);
    }
    return events;
  }

  public getEvents(forceUpdate: boolean) {
    if (this.mappedEvents != undefined && forceUpdate == false) {
      return Observable.create((observer) => {observer.next();}).pipe(map((response: any) => {return this.createEventsArray(this.mappedEvents);}));
    } else {
      return this.req.get("/htv/events", {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')}).pipe(map((response: any) => {
        this.mappedEvents = {};
        if (response && response.data) {
          for (var i in response.data) {
            var event = new EventModel();
            event.id = response.data[i].eventId;
            event.name = response.data[i].name;

            this.mappedEvents[event.id] = event;
          }
        }
        return this.createEventsArray(this.mappedEvents);
      }));
    }
  }

  public getUpcomingEvents(forceUpdate: boolean) {
    if (this.mappedUpcomingEvents != undefined && forceUpdate == false) {
      return Observable.create((observer) => {observer.next();}).pipe(map((response: any) => {return this.createEventsArray(this.mappedUpcomingEvents);}));
    } else {
      return this.req.get("/htv/upcoming_events", {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')}).pipe(map((response: any) => {
        this.mappedUpcomingEvents = {};
        if (this.mappedEvents == undefined) {
        	this.mappedEvents = {};
        }
        if (response && response.data) {
          for (var i in response.data) {
            var event = new EventModel();
            event.id = response.data[i].EventId;
            event.name = response.data[i].Name;

            this.mappedUpcomingEvents[event.id] = event;
            this.mappedEvents[event.id] = event;
          }
        }
        return this.createEventsArray(this.mappedUpcomingEvents);
      }));
    }
  }

  public getEvent(eventId: number): EventModel {
    return this.mappedEvents[eventId];
  }
}
