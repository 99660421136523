interface IEventModel {
  id: number;
  name: string;
}

export class EventModel {
  public id: number;
  public name: string;

  constructor();
  constructor(obj: IEventModel);
  constructor(obj?: any) {
    this.id = obj && obj.id || 0;
    this.name = obj && obj.name || "";
  }
}
