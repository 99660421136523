import { Component, OnInit, EventEmitter, Input, Output, ElementRef, HostListener } from '@angular/core';

export class KeyIndex{
	constructor(
		public value: string,
		public index: number,
	){}
}

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent {

  @Input() label: string = ""; //What you want the search box to be labeled/titled as
  @Input() allData: any = []; //Pass in a array/slice of data of any kind.
  @Input() keys: string = ""; //Indicate which keys are available in the allData set.
  @Input() textFormat: string = ""; //How the text will be formated and seen. Use #'s on either side of defined keys to have values dynamically outputed
  @Input() defaultValue: any; //The default value you want to be selected from the allData if it exists.
  @Input() defaultValueOnly: boolean = false; //When true
  @Input() valuesOnly: boolean = false; //When true indicates the provided data is not objects but rather an array/slice of data entries. e.g ["firstname","lastname","middlename"]
  @Input() neverClear: boolean = false; //Will never clear out any of the text automatically.

  @Output() onSelect = new EventEmitter<any>();
  @Output() clearSelected = new EventEmitter<any>();

  showDropDown: boolean = false;
  mappedKeys: string[] = [];
  searchText: string = "";
  currentOption: any = undefined;
  filteredData: any = [];

  @HostListener('document:keyup', ['$event']) 
  @HostListener('document:click', ['$event'])
  clickout(event) {
  	if(this.elementRef.nativeElement.contains(event.target)) {
  		this.filteredData = this.filterSearch(this.searchText, false);
  		this.showDropDown = true;
  	} else {
  		//update search text to be blank if no option is currently selected.
  		if (this.currentOption == undefined && this.showDropDown == true && this.neverClear == false) {
  			this.searchText = "";
  		}
      // If set to never clear always update the search text to be the current option as that is the last known option that
      // is to be used in whatever is requested. This allows for users to select options as well as enter ones that do not exist
      if (this.neverClear == true) {
        this.currentOption = this.searchText;
      }
  		// If leaving the search section emit the last known option selected. Good for if a second search
  		// was performed ontop of a previously selected option but not option was selected
  		if (this.showDropDown == true) {
  			this.onSelect.emit(this.currentOption);
  		}
      this.showDropDown = false;
  	}
  }

  constructor(
  	private elementRef: ElementRef
  ) {}

  ngOnInit() {
  	this.generateKeys();
    this.currentOption = this.defaultValue;
    this.searchText = this.getFormatedDataString(this.defaultValue, this.textFormat);
  	this.filteredData = this.filterSearch(this.searchText, false);
  }

  //Get the list of data keys to be used 
  generateKeys() {
  	var splitKeys = this.keys.split(",");
  	for (var i in splitKeys) {
  		this.mappedKeys.push(splitKeys[i]);
  	}
  }

  getFormatedDataString(data: any, format: string): string {
    if (data == undefined) {
      return "";
    } else if (this.valuesOnly == true || this.defaultValueOnly == true) {
      if (data == undefined) {
        return "";
      } else {
        this.defaultValueOnly = false;
        return data;
      }
    } else {
  	  for (var i in this.mappedKeys) {
        if (data[this.mappedKeys[i]] == undefined) {
          return "";
        }
  		  format = format.replace("#"+this.mappedKeys[i]+"#",data[this.mappedKeys[i]]);
  	  }
  	  return format;
    }
  }

  filterSearch(search: string, modifying: boolean): any {
    if (modifying && this.currentOption != undefined) {
      this.clearSelected.emit();
    }

    this.searchText = search.replace(/^[ ]+|[ ]+$/g,'');
    var filteredData = this.allData;
    if (this.searchText.length > 0) {
      filteredData = [];
      for (var i=0; i < this.allData.length; i++) {
        if((this.getFormatedDataString(this.allData[i], this.textFormat).toLowerCase()).includes(this.searchText.toLowerCase())) {
          filteredData.push(this.allData[i]);
        }
      }
    }
    return filteredData;
  }

  selectOption(search: string, data: any) {
  	this.filteredData = this.filterSearch(search, false);
  	this.showDropDown = false;
  	this.currentOption = data;
  	this.onSelect.emit(data);
  }

}
