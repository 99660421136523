import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';

//Services
import {LeaguestatService} from '../../services/leaguestat/leaguestat.service';
import {TeamsService} from '../../services/teams/teams.service';

//Models
import {TeamModel} from '../../models/team';
import {LinkedTeamModel} from '../../models/linked-team';
import {ClientTeamLinkModel} from '../../models/client-team-link';

@Component({
  selector: 'app-teamManagement',
  templateUrl: './ls-teams-management.component.html',
  styleUrls: ['./ls-teams-management.component.scss']
})
export class LSTeamsManagementComponent implements OnInit {

  loaded: boolean
  currentClient : string
  lsClients: string[] = [];
  
  lsTeams: TeamModel[] = [];
  htvTeams: TeamModel[] = [];
  linkedTeams: ClientTeamLinkModel[] = []
  mappedLSTeams: LinkedTeamModel[] = [];

  filteredLSTeamsSearchText: string = "";
  filteredLSTeams: LinkedTeamModel[];
  
  editingTeams: {[id: string]:{[venueId:number]: boolean}} = {};

  constructor(
    private leaguestatService: LeaguestatService,
    private teamsService: TeamsService,
  ){}

  ngOnInit() {
    this.loaded = false;
    this.leaguestatService.getLSHTVClients(false).subscribe(
      data => {
        this.lsClients = data;
        this.currentClient = this.lsClients[0];
        this.getHTVTeams();
      },
      error => {
        console.error(error);
        alert("Failed to get HTV Leageuestat Clients");
      }
    );
  }

  updateClientCode(clientCode: string) {
    //Update client and then clear out old data before getting new data.
    this.loaded = false;
    this.currentClient = clientCode;
    this.filteredLSTeamsSearchText = "";
    this.getLSTeams();
  }

  resetEditingBoxes() {
    this.editingTeams[this.currentClient] = {};
    for (var i=0; i < this.lsTeams.length; i++) {
      this.editingTeams[this.currentClient][this.lsTeams[i].id] = false;
    }
  }

  removeTeamMapping(lsTeamId: number) {
    this.teamsService.removeTeamLink(this.currentClient, lsTeamId).subscribe(
      data => {},
      error => {
        console.error(error);
        alert("Something went wrong when updating link to team");
      }
    );
  }

  updateTeamMapping(lsTeamId: number, htvTeam: TeamModel) {
    if (htvTeam == undefined){
      this.teamsService.removeTeamLink(this.currentClient, lsTeamId).subscribe(
        data => {
          this.getLinkedTeams();
        },
        error => {
          console.error(error);
          alert("Something went wrong when removing link to team.");
        }
      );
    } else {
      this.teamsService.updateTeamLink(this.currentClient, lsTeamId, htvTeam.id).subscribe(
        data => {
          this.getLinkedTeams();
        },
        error => {
          console.error(error);
          alert("Something went wrong when updating link to team. Possibly already linked to another team.");
        }
      );
    }
  }

  editTeam(teamId: number): void {
    this.resetEditingBoxes();
    this.editingTeams[this.currentClient][teamId] = true;
  }

  saveTeam(teamId: number): void {
    this.editingTeams[this.currentClient][teamId] = false;
    this.getLinkedTeams();
  }

  linkedTeamsFilterSearch(search: string, teams: LinkedTeamModel[]): LinkedTeamModel[] {
    this.filteredLSTeamsSearchText = search;
    search = search.replace(/^[ ]+|[ ]+$/g,'');
    var filteredTeams = teams;
    if (search.length > 0) {
      filteredTeams = [];
      for (var i=0; i < teams.length; i++) {
        let teamValue = (teams[i].lsTeam.id+" "+teams[i].lsTeam.name).toLowerCase();
        if(teamValue.includes(search.toLowerCase())) {
          filteredTeams.push(teams[i]);
        }
      }
    }
    return filteredTeams;
  }

  getHTVTeams() {
    // Get the HTV arenas
    this.teamsService.getHTVTeams(false).subscribe(
      data => {
        this.htvTeams = data;
        this.getLSTeams();
      },
      error => {
        console.error(error);
        alert("Failed to get HTV Teams"); 
      }
    );
  }

  getLSTeams() {
    // Get the LS venues for the currently selected client
    this.teamsService.getLSTeams(false, this.currentClient).subscribe(
      data => {
        this.lsTeams = data;
        this.resetEditingBoxes();
        this.getLinkedTeams();
      },
      error => {
        console.error(error);
        alert("Failed to get Leaguestat Teams");
      }
    );
  }

  getLinkedTeams() {
    // Get the LS HTV linked arenas
    this.teamsService.getLinkedTeams(true, this.currentClient).subscribe(
      data => {
        this.linkedTeams = data;
        this.mapLinkedTeams();
        this.loaded = true;
      },
      error => {
        console.error(error);
        alert("Failed to get linked teams list");
      }
    );
  }

  mapLinkedTeams() {
    let htvTeam: TeamModel;
    this.mappedLSTeams = [];

    for (let j=0; j < this.lsTeams.length; j++) {
      let found = false
      let lsTeam = this.lsTeams[j];
      for (let i=0; i < this.linkedTeams.length; i++) {
        let linkedTeam = this.linkedTeams[i];
        if (linkedTeam.lsTeamId == lsTeam.id) {
          htvTeam = this.teamsService.getHtvTeam(linkedTeam.htvTeamId);
          if (htvTeam.id != 0) {
            found = true;
          }
        }
      }
      if (found) {
        this.mappedLSTeams.push(new LinkedTeamModel({lsTeam: lsTeam, htvTeam: htvTeam}));
      } else {
        this.mappedLSTeams.push(new LinkedTeamModel({lsTeam: lsTeam, htvTeam: undefined}));
      }
    }
    this.filteredLSTeams = this.linkedTeamsFilterSearch(this.filteredLSTeamsSearchText, this.mappedLSTeams)
  }
}
