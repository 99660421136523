import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

//Services
import {FacilityService} from '../facility/facility.service';
import {EventsService} from '../events/events.service';
import {HockeytvConstantsService} from '../hockeytv-constants/hockeytv-constants.service';
import {Req} from '../request/request.service';
import {TeamsService} from '../teams/teams.service';

//Models
import {GameModel} from '../../models/game'
import {GameCreateRequest} from '../../models/game-create-request'




@Injectable({
  providedIn: 'root'
})
export class GameGenerationService {

  clientCode: string = "";
  games: GameModel[] = [];
  createdGames: GameModel[] = [];

  constructor(
  	private facilityService: FacilityService,
    private eventsService: EventsService,
    private hockeytvConstantsService: HockeytvConstantsService,
  	private req: Req,
  	private teamsService: TeamsService,
  ){}

  public setClientCode(clientCode: string): void {
  	this.clientCode = clientCode;
  }

  //Set games returns true if all games are okay. Returns false if it was unable to setup the games
  public setGames(games: GameModel[]): boolean {
  	this.games = games;
  	if (this.cleanUpGames() == false) {
      this.games = [];
      return false;
    }
    return true;
  }

  private cleanUpGames(): boolean {
  	for(var i in this.games) {
      this.games[i].id = 0;
  		if (this.games[i].homeTeam == undefined) {
  			return false;
  		}
  		if (this.games[i].visitorTeam == undefined) {
  			return false;
  		}
  	}
    return true;
  }

  public getNumberOfGames(): number {
    return this.games.length;
  }

  public popGame(): GameModel {
    var game = this.games[0];
    if (game == undefined) {
      return undefined;
    }
    this.games.splice(0, 1);
    return game;
  }

  public createLeaguestatGame(game: GameModel) {
    var eventId = 0;
    if (game.event != undefined) {
      eventId = game.event.id
    }

    var encoderId = 0;
    if (game.encoder != undefined) {
      encoderId = game.encoder.id;
    }

    var pixellotUnitId = 0;
    if (game.pixellotUnit != undefined) {
      pixellotUnitId = game.pixellotUnit.id;
    }

  	var data = {
  		clientCode: this.clientCode,
  		leaguestatGameId: game.leaguestatGameId,
  		leagueId: game.leagueId,
  		seasonId: game.seasonId,
      eventId: eventId,
  		homeTeamId: game.homeTeam.id,
  		visitorTeamId: game.visitorTeam.id,
  		gameDate: game.gameDate,
  		gameTime: game.gameTime,
  		timeZone: game.timeZone,
  		facilityId: game.facility.id,
  		encoderId: encoderId,
  		pixellotUnitId: pixellotUnitId,
      gameTypeId: game.gameType.id,
  	};
  	return this.req.post("/htv/leaguestat/"+this.clientCode+"/createLeaguestatGame", data ,{API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')}).pipe(map((response: any) => {
      if (response && response.data) {
        var game = new GameModel();
        game.id = response.data.gameId;
        game.leaguestatGameId = response.data.scoringProviderGameId;
        game.leagueId = response.data.leagueId;
        game.seasonId = response.data.seasonId;
        game.homeTeam = this.teamsService.getHtvTeam(response.data.homeTeamId);
        game.visitorTeam = this.teamsService.getHtvTeam(response.data.visitorTeamId);
        game.facility = this.facilityService.getFacility(response.data.facility.facilityId);
        game.encoder = this.facilityService.getEncoder(response.data.encoder.id);
        game.pixellotUnit = this.facilityService.getPixellotUnit(response.data.pixellotUnit.id);
        game.gameDate = response.data.gameDate;
        game.gameTime = response.data.gameTime;
        game.timeZone = "Canada/Eastern";
        game.gameType = this.hockeytvConstantsService.getGameType(response.data.gameTypeId);
        game.event = this.eventsService.getEvent(response.data.eventId)

        return game;
      }
      return undefined;
    }));
  }

  public getUpcomingLeaguestatGamesBySeason(forceUpdate: boolean, clientCode: string, seasonId: number) {
    return this.req.get("/htv/leaguestat/"+clientCode+"/upcomingGames/"+seasonId, {API_KEY: localStorage.getItem('currentUser'), APP_ID: localStorage.getItem('currentAppId'), APP_KEY: localStorage.getItem('currentAppKey')}).pipe(map((response: any) => {
      if (response && response.data) {
        var games: GameModel[] = [];
        for (var i in response.data) {
          var game = new GameModel();
          game.id = response.data[i].gameId;
          game.leaguestatGameId = response.data[i].scoringProviderGameId;
          game.leagueId = response.data[i].leagueId;
          game.seasonId = response.data[i].seasonId;
          game.homeTeam = this.teamsService.getHtvTeam(response.data[i].homeTeamId);
          game.visitorTeam = this.teamsService.getHtvTeam(response.data[i].visitorTeamId);
          game.facility = this.facilityService.getFacility(response.data[i].facility.facilityId);
          game.encoder = this.facilityService.getEncoder(response.data[i].encoder.id);
          game.pixellotUnit = this.facilityService.getPixellotUnit(response.data[i].pixellotUnit.id);
          game.gameDate = response.data[i].gameDate;
          game.gameTime = response.data[i].gameTime;
          game.timeZone = "Canada/Eastern";
          game.gameType = this.hockeytvConstantsService.getGameType(response.data[i].gameTypeId);
          game.event = this.eventsService.getEvent(response.data[i].eventId)

          games.push(game);
        }
        return games
      }
      return undefined;
    }));
  }
}
