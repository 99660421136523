import {TeamModel} from './team';

interface ILinkedTeamModel {
  lsTeam: TeamModel;
  htvTeam: TeamModel;
}

export class LinkedTeamModel {
  public lsTeam: TeamModel;
  public htvTeam: TeamModel;

  constructor();
  constructor(obj: ILinkedTeamModel);
  constructor(obj?: any){
    this.lsTeam = obj && obj.lsTeam || undefined;
    this.htvTeam = obj && obj.htvTeam || undefined;
  }
}
