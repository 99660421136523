interface ILeagueModel {
    id: number;
    name: string;
}

export class LeagueModel {
  public id: number;
  public name: string;

  constructor();
  constructor(obj: ILeagueModel)
  constructor(obj?: any){
      this.id = obj && obj.id || 0;
      this.name = obj && obj.name || "";
  }
}
