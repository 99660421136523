interface ICountryModel {
  countryCode: string;
  countryName: string;
}

export class CountryModel {
  public countryCode: string;
  public countryName: string;

  constructor();
  constructor(obj: ICountryModel);
  constructor(obj?: any) {
    this.countryCode = obj && obj.countryCode || "";
    this.countryName = obj && obj.countryName || "";
  }
}
