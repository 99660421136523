//Angular
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';

//Models
import {AppRoutingModule} from './app-routing.module';

//Services
import {EventsService} from './services/events/events.service';
import {FacilityService} from './services/facility/facility.service';
import {GameGenerationService} from './services/game-generation/game-generation.service';
import {GamesImportService} from './services/games-import/games-import-service';
import {HockeytvConstantsService} from './services/hockeytv-constants/hockeytv-constants.service';
import {Req} from './services/request/request.service';
import {SeasonImportService} from './services/season-import/season-import.service';
import {TeamsService} from './services/teams/teams.service';

//Guard
import {AuthGuard} from './guards/auth/auth.guard';

//Components
import {AppComponent} from './components/app/app.component';
import {FacilityManagementComponent} from './components/facility-management/facility-management.component';
import {FacilityViewComponent} from './components/facility-management/facility-view/facility-view.component';
import {ImportGamesComponent} from './components/import-games/import-games.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {GamesReviewComponent} from './components/games-review/games-review.component';
import {LoginComponent} from './components/login/login.component';
import {LoginFormComponent} from './components/login/login-form/login-form.component';
import {LSTeamsManagementComponent} from './components/ls-teams-management/ls-teams-management.component';
import {LSVenuesManagementComponent} from './components/ls-venues-management/ls-venues-management.component';
import {GeneratingGamesComponent} from './components/generating-games/generating-games.component';
import {AutoFocusDirective} from './directives/auto-focus/auto-focus.directive';
import {SearchBoxComponent} from './components/widgets/search-box/search-box.component';
import {CopyrightComponent} from './components/widgets/copyright/copyright.component';


@NgModule({
  declarations: [
    AppComponent,
    FacilityManagementComponent,
    FacilityViewComponent,
    ImportGamesComponent,
    DashboardComponent,
    GamesReviewComponent,
    LoginComponent,
    LoginFormComponent,
    LSTeamsManagementComponent,
    LSVenuesManagementComponent,
    GeneratingGamesComponent,
    AutoFocusDirective,
    SearchBoxComponent,
    CopyrightComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [
    AuthGuard,
    EventsService,
    FacilityService,
    GameGenerationService,
    HockeytvConstantsService,
    Req,
    SeasonImportService,
    TeamsService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
